import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import QuestionComponent from "components/session/QuestionComponent";
import { getAllCategories, getSavedQuestions } from "store/actions";
import "styles/sessions.css";
import { removeSavedQuestionApi, removeSavedQuestionNosession, serarchInSaved } from "api/sessions";
import { toast } from "react-toastify";
import Select from "react-select";
import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";
import { getId } from "helpers/functions";
import { IoClose, IoSearch } from "react-icons/io5";
import { FaHeart } from "react-icons/fa";
import { FaCircleQuestion, FaQuestion } from "react-icons/fa6";
import server from "api/server";
import Cookies from "js-cookie";

const View = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  console.log(searchResults + "searchResults")
  const handleSearch = async () => {
    try {
      const finalQuery = searchQuery.trim(); // Use the searchQuery as is without defaulting to 'a'
  
      const response = await server().get(`/auth/pinQuestions/search`, {
        params: {
          query: finalQuery, // This will be empty if searchQuery is empty
          type: 'saved',
        },
      });
  
      if (response.status === 200) {
        setSearchResults(response.data);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };
  
  const handleInputChange = (e) => {
    const newQuery = e.target.value.trim(); // Trim the input
    setSearchQuery(newQuery); // Set the searchQuery as is
    setQuery(newQuery); // Update the query state
  };
  
  useEffect(() => {
    handleSearch();
  }, [searchQuery]);


 
  const getOptions = (arr) =>
    arr?.length > 0
      ? arr?.map((e) => ({
        label: e?.name?.[Cookies.get('site-lang') || 'ar'], // Changed to use Cookies
        value: e?.id || e?._id,
        subCategories: e?.subCategories || [],
        questionsCount: e?.questionsCount || "",
      }))
      : [{ label: formatMessage({ id: "no-options" }) }];

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const [selectedSubCategoryChild, setSelectedSubCategoryChild] = useState();
  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);

  const { categories } = useSelector((state) => state.categories);

  const { savedLoading, loading, savedQuestions } = useSelector(
    (state) => state.sessions
  );

  useEffect(() => {
    dispatch(getSavedQuestions());
    // if (!categories?.length > 0)
    dispatch(getAllCategories());
  }, [dispatch]);

  // const filteredQuestions = savedQuestions?.filter(
  //   (q) =>
  //     q?.question?.category ===
  //     (selectedSubCategoryChild?.value ||
  //       selectedSubCategory?.value ||
  //       q?.question?.category)
  // );

  const thirdCatClass =
    subCategoriesChildren?.length > 0 ? "col-xl-3 col-md-4" : "";

  if (loading || savedLoading) return <Loader />;

  return (
    <div>

      <div className="welcome">
        <Row className="align-items-center">
          <Col md={6} >
            <div className="info">
              <h2>  <FormattedMessage id="savedQuestions" /></h2>
            </div>
          </Col>
          <Col md={6} >
            <form>
              <div className="search position-relative">
                <IoSearch />
                <input
                  type="search"
                  placeholder="search"
                  value={query}
                  onChange={handleInputChange}
                />
              </div>
            </form>

          </Col>
        </Row>

      </div>
      <div className="card rounded-0">
        <div className="view-page session-add">
          <div className="session-exam">
            {searchResults?.data?.searchedQuestions.length ? (
              <div className="details-wrapper row">
                {searchResults.data.searchedQuestions
                  .filter((ele) => !ele.isDeleted)
                  .map((data, index) => {
                    const explanation = data?.question?.explanation || "";
                    const title = data?.question?.title || "";
                    const answers = data.question.answers || [];
                    console.log(title)
                    return (
                      <SavedQuestion
                        data={data}
                        index={index}
                        key={index}
                        searchQuery={searchQuery}
                        setSearchResults={setSearchResults}
                        explanation={explanation}
                        title={title}
                        answers={answers.map((answer) => ({
                          text: answer.answer,
                          isCorrect: answer.isCorrect
                        }))}
                      />
                    );
                  })}
              </div>
            ) : (
              <span className="my-5 alert alert-warning text-center">
                <FormattedMessage id="notFound" />
              </span>
            )}


          </div>
        </div>
      </div>
    </div >
  );
};

const SavedQuestion = ({
  data,
  index,
  searchQuery,
  setSearchResults,
  answers,
  explanation,
  title,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [discussQuestion, setDiscussion] = useState(false);
  const [testAnswer, setTestAnswer] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [show, setShow] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const urlIncludesSaveQ = window.location.pathname.includes('saved-questions');

  const toggleDiscussion = () => {
    setTestAnswer(false);
    setDiscussion(!discussQuestion);
  };

  const removeSavedQuestionNosessions = async () => {
    try {
      const res = await removeSavedQuestionNosession(data?.id || data?._id);
      if (!res?.statusCode) {
        const searchParams = { query: searchQuery || '', type: 'saved' };
        const response = await serarchInSaved(searchParams);
        setSearchResults(response);
        // setRemoving(true);
        toast.success(formatMessage({ id: 'question-removed' }));
      } else {
        toast.error(formatMessage({ id: 'sendingErrorMessage' }));
      }
    } catch (error) {
      // setRemoving(false);
      toast.error(
        formatMessage({
          id:
            error?.response?.data?.message === 'notSubscribed'
              ? 'subscription-not-found'
              : 'sendingErrorMessage',
        })
      );
    }
  };

  const removeSavedQuestion = async () => {
    try {
      const res = await removeSavedQuestionApi(data?.id || data?._id);
      if (!res?.statusCode) {
        dispatch(getSavedQuestions());
        toast.success(formatMessage({ id: 'question-removed' }));
      } else {
        toast.error(formatMessage({ id: 'sendingErrorMessage' }));
      }
    } catch (error) {
      toast.error(
        formatMessage({
          id:
            error?.response?.data?.message === 'notSubscribed'
              ? 'subscription-not-found'
              : 'sendingErrorMessage',
        })
      );
    }
  };

  const showModalEX = () => {
    setShowComments(true);
    setShow(false);
  };

  if (removing) return <Loader />;
  if (!data) return null;

  return (
    <>
      {title ? (
        <div className="col-md-6">
          <div className="saved-question-wrapper saved">
            {!urlIncludesSaveQ && (
              <button className="heart heart_BTNSAVE" onClick={removeSavedQuestion}>
                <FaHeart />
              </button>
            )}

            {urlIncludesSaveQ && (
              <div className="icons">
                <button className="help" onClick={() => setShow(true)}>
                  <FaCircleQuestion />
                </button>
                <button className="heart heart_BTNSAVE" onClick={removeSavedQuestionNosessions}>
                  <FaHeart />
                </button>
              </div>
            )}

            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="div_modalSave">
                <button className="btn_transparent IoClose" onClick={() => setShow(false)}>
                  <IoClose />
                </button>
                <div className="explanation_modal title">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <Row className="answers_in_commented mt-4 text-start">
                  {answers.map((q, index) => (
                    <Col md={6} key={index}>
                      <div className={q.isCorrect ? 'sucess' : 'erorr'}>{q.text}</div>
                    </Col>
                  ))}
                </Row>
                <div className="d-flex align-items-center justify-content-around gap-3">
                  <button onClick={showModalEX} className="notes" type="button">
                    <FormattedMessage id="explain-question" />
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showComments}
              onHide={() => setShowComments(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="div_modalSave">
                <button className="btn_transparent IoClose" onClick={() => setShowComments(false)}>
                  <IoClose />
                </button>
                <div className="explanation_modal">
                  <div dangerouslySetInnerHTML={{ __html: explanation }} />
                </div>
              </Modal.Body>
            </Modal>

            <QuestionComponent
              questionsState={[]}
              setQuestionsState={() => {}}
              questionsIndex={index}
              question={{ ...data?.question, index }}
              testAnswer={testAnswer}
              discussQuestion={discussQuestion}
              setDiscussion={setDiscussion}
              setTestAnswer={setTestAnswer}
              toggleDiscussion={toggleDiscussion}
              viewOnly={true}
              inputParams={{ readOnly: true }}
            />
          </div>
        </div>
      ) : (
        <span className="position-relative rounded-1 w-100 p-3 bg-warning bg-opacity-75 border-warning text-black bahij-bold">
          <FormattedMessage id="emptyQuestion" />
        </span>
      )}
    </>
  );
};

export default View;






// {/* {savedQuestions?.length > 0 && (
//     <>
//       <div className="filter-row">
//         <div className="card ">
//           <Row className="align-items-end">
//             <Col className={`col-12 col-md-4 ${thirdCatClass}`}>
//               <div className="form-group ">
//                 <h5>
//                   <FormattedMessage id={"parent-section"} />
//                 </h5>
//                 <Select
//                   placeholder={<FormattedMessage id={"parent-section"} />}
//                   options={getOptions(
//                     categories.filter((e) => !e?.category)
//                   )}
//                   onChange={(selectedOption) => {
//                     setSelectedCategory(selectedOption);
//                     setSelectedSubCategory({ label: "" });
//                     setSubCategoriesChildren([]);
//                   }}
//                   value={selectedCategory}
//                   isRtl={true}
//                   isSearchable={false}
//                   className="basic-single"
//                   classNamePrefix="select"
//                 />
//               </div>
//             </Col>
//             <Col className={`col-12 col-md-5 ${thirdCatClass}`}>
//               <div className="form-group ">
//                 <h5>
//                   <FormattedMessage id={"secondary-section"} />
//                 </h5>
//                 <Select
//                   placeholder={
//                     <FormattedMessage id={"secondary-section"} />
//                   }
//                   options={getOptions(selectedCategory?.subCategories)}
//                   onChange={(selectedOption) => {
//                     setSelectedSubCategory(selectedOption);
//                     if (selectedOption?.value?.length > 0)
//                       setSubCategoriesChildren(
//                         categories?.filter(
//                           (e) => getId(e?.category) == selectedOption?.value
//                         )
//                       );
//                     setSelectedSubCategoryChild();
//                   }}
//                   value={selectedSubCategory}
//                   isRtl={true}
//                   isSearchable={false}
//                   className="basic-single"
//                   classNamePrefix="select"
//                 />
//               </div>
//             </Col>
//             {subCategoriesChildren?.length > 0 && (
//               <Col className={`col-12 col-md-5 ${thirdCatClass}`}>
//                 <div className="form-group ">
//                   <h5>
//                     <FormattedMessage id={"subCategoryChild"} />
//                   </h5>
//                   <Select
//                     placeholder={
//                       <FormattedMessage id={"subCategoryChild"} />
//                     }
//                     options={getOptions(subCategoriesChildren)}
//                     onChange={(selectedOption) => {
//                       setSelectedSubCategoryChild(selectedOption);
//                     }}
//                     value={selectedSubCategoryChild}
//                     isRtl={true}
//                     isSearchable={false}
//                     className="basic-single"
//                     classNamePrefix="select"
//                   />
//                 </div>
//               </Col>
//             )}
//             <Col className="col-12 col-md-3">
//               <div className="form-group ">
//                 <Button
//                   onClick={() => {
//                     setSelectedCategory({});
//                     setSelectedSubCategory({});
//                     setSelectedSubCategoryChild({});
//                     setSubCategoriesChildren([]);
//                   }}
//                   disabled={!selectedSubCategory?.value}
//                 >
//                   <FilterIcon fill="#fff" />
//                   <FormattedMessage id={"clear-filter"} />
//                 </Button>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   )} */}

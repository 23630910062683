import { v4 } from "uuid";
import { ReactComponent as Session } from "assets/svgs/sessions.svg";
import { ReactComponent as Subscriptions } from "assets/svgs/subscriptions.svg";
import { ReactComponent as Home } from "assets/svgs/home.svg";
import { ReactComponent as Star } from "assets/svgs/star.svg";
import { ReactComponent as Comment } from "assets/svgs/chat-square-text.svg";
import { FaBoxArchive, FaRegClock } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { MdInsertComment, MdSubscriptions } from "react-icons/md";
import { BsFileEarmarkCheckFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa6";
import { FaClockRotateLeft } from "react-icons/fa6";

export const sidebarData = [
  {
    id: v4(),
    to: "/",
    text: "home",
    svg: <FaHome />,
  },
  {
    id: v4(),
    to: "/subscriptions",
    text: "subscriptions",
    svg: <MdSubscriptions />,
  },
  {
    id: v4(),
    to: "/saved-questions",
    text: "savedQuestions",
    subscriptionSensitive: true,
    svg:  <BsFileEarmarkCheckFill />,
  },
  {
    id: v4(),
    to: "/commented-on",
    text: "commentedOn",
    subscriptionSensitive: true,
    svg: <MdInsertComment />,
  },
  {
    id: v4(),
    to: "sessions",
    text: "sessions",
    subscriptionSensitive: true,
    svg: <FaClock />,
    children: [
      {
        id: v4(),
        svg: <FaClockRotateLeft />  ,
        to: "/sessions",
        text: "all-sessions",
      },
      {
        id: v4(),
        svg: <FaBoxArchive />,
        to: "/sessions/archived",
        text: "archived",
      },
    ],
  },
];

export const firebaseConfig = {
  apiKey: "AIzaSyAxZznEff8MhJkLbBUF6b5C-V046B1c7VI",
  authDomain: "examist-6ed98.firebaseapp.com",
  databaseURL: "https://examist-6ed98-default-rtdb.firebaseio.com",
  projectId: "examist-6ed98",
  storageBucket: "examist-6ed98.appspot.com",
  messagingSenderId: "37457174691",
  appId: "1:37457174691:web:3ed6f82606f33fa6ca1f16",
  measurementId: "G-64WM1DX1NM",
};

// question types
// enum: ["checkbox", "multiselect"],
export const questionType = [
  {
    id: "checkbox",
    name: {
      ar: "اختيار واحد",
      en: "single choice",
    },
  },
  {
    id: "multiselect",
    name: {
      ar: "اختيار من متعدد",
      en: "multichoice",
    },
  },
];

export const genderSelectOptions = [
  {
    id: v4(),
    label: "Male",
    value: "male",
  },
  {
    id: v4(),
    label: "Female",
    value: "female",
  },
];

export const typeSelectOptions = [
  {
    id: v4(),
    label: "Individual",
    value: "individual",
  },
  {
    id: v4(),
    label: "Company",
    value: "company",
  },
];

export const roleSelectOptions = [
  {
    id: v4(),
    label: "Admin",
    value: "admin",
  },
  {
    id: v4(),
    label: "User",
    value: "user",
  },
];

export const levelSelectOptions = [
  {
    id: v4(),
    label: "Entry",
    value: "entry",
  },
  {
    id: v4(),
    label: "Intermediate",
    value: "intermediate",
  },
  {
    id: v4(),
    label: "Expert",
    value: "expert",
  },
];
export const jobTypeSelectOptions = [
  {
    id: v4(),
    label: "PartTime",
    value: "partTime",
  },
  {
    id: v4(),
    label: "FullTime",
    value: "fullTime",
  },
];

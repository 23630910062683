import images from 'react-payment-inputs/images';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CouponDiscount from './CouponDiscount';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { sendCouponCode } from 'store/payment/actions';
import server from 'api/server';
import { getSubscription } from 'store/actions';

export default function TamaraPay({ setCouponCode, paymentMethods }) {
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const { id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { coupon, couponLoading } = useSelector((state) => state.payment);
  const { singleSubscription } = useSelector((state) => state?.subscriptions);

  const [subName, setSubName] = useState('');
  const [subPrice, setSubPrice] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');


  useEffect(() => {
    dispatch(getSubscription(id));
  }, [dispatch]);

  const handleInputChange = (setter) => (e) => setter(e.target.value);

  const TypepaymentMethods = paymentMethods.map((ele, idx) => ele.id)

 
  const handleSubmit = async () => {
    // if (!code) {
    //   toast.error(formatMessage({ id: "couponRequired" }));
    //   return;
    // }

    const data = {
      paymentMethod: TypepaymentMethods.join(', '),
      subName: singleSubscription?.price,
      subPrice: singleSubscription?.plan,
      subscription: singleSubscription?.id,
      type: "tamara",
      firstName,
      lastName,
      phoneNumber,
      email,
    };

    try {
      const response = await server().post(`/auth/userSubscriptions`, data);
      toast.success(formatMessage({ id: "couponApplied" }));

      // Redirecting to the URL provided in the response
      window.location = response.data.redirect_url;

      console.log(response.data);
    } catch (error) {
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  return (
    <div className='card-body payment-method'>
      <form className='card formTamar'>
        <Row className='w-100'>
          <Col xs='12'>
            <div>
              <FormattedMessage id='firstName' />
            </div>
            <label>
              <input
                type='text'
                name='lastName'
                value={firstName}
                onChange={handleInputChange(setFirstName)}
                required
              />
            </label>
          </Col>
        </Row>

        <Row className='w-100'>
          <Col xs='12'>
            <div>
              <FormattedMessage id='lastName' />
            </div>
            <label>
              <input
                type='text'
                name='lastName'
                value={lastName}
                onChange={handleInputChange(setLastName)}
                required
              />
            </label>
          </Col>
        </Row>
        <Row className='w-100'>
          <Col xs='12'>
            <div>
              <FormattedMessage id='phoneNumber' />
            </div>
            <label>
              <input
                type='text'
                name='phoneNumber'
                value={phoneNumber}
                onChange={handleInputChange(setPhoneNumber)}
                required
              />
            </label>
          </Col>
        </Row>
        <Row className='w-100'>
          <Col xs='12'>
            <div>
              <FormattedMessage id='email' />
            </div>
            <label>
              <input
                type='email'
                name='email'
                value={email}
                onChange={handleInputChange(setEmail)}
                required
              />
            </label>
          </Col>
        </Row>
        {/* <Row className='w-100 mt-2'>
          <Col className='col-12 col-md-6'>
            <div>
              <FormattedMessage id='expiration-date' />
            </div>
            <label>
              <input {...getExpiryDateProps()} name='date' required />
            </label>
          </Col>
          <Col className='col-12 col-md-6'>
            <div>
              <FormattedMessage id={meta?.cardType?.code?.name || 'CVV'} />
            </div>
            <label>
              <input {...getCVCProps()} type='password' name='cvc' required />
            </label>
          </Col>
        </Row> */}
        <Row className='w-100 mt-2'>
          <div>
            <FormattedMessage id="coupon" />
          </div>
          <label>
            <input
              type="text"
              name="coupon"
              onChange={(e) => setCode(e.target.value)}
              disabled={coupon}
            />
          </label>

          <button
            style={{
              backgroundColor: coupon ? "#35a735" : "",
            }}
            disabled={couponLoading || coupon}
            onClick={handleSubmit}
            type="button"
          >
            <FormattedMessage id='pay' />

            {/* {couponLoading ? (
              "Loading..."
            ) : coupon ? (
              <FormattedMessage id="couponApplied" />
            ) : (
              <FormattedMessage id="applyCoupon" />
            )} */}
          </button>
        </Row>
      </form>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import 'styles/payment.css';
import { getCurrentUserStatistics } from 'store/actions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import { ReactComponent as ExclamationIcon } from 'assets/svgs/exclamation-round-line-icon.svg';

const Payment = () => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    dispatch(getCurrentUserStatistics());
  }, [dispatch]);

  const { userStats, loading, error } = useSelector(
    (state) => state.authentication
  );
  useEffect(() => {
    if (error) {
      if (error.statusCode == 404)
        toastErrorMessage(
          `${formatMessage({ id: 'item' })} ${formatMessage({
            id: 'no-exist',
          })}`,
          locale
        );
      else
        toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
    }
  }, [error]);

  // if (loading) return <Loader />;
  return (
    <div className='view-page payment-page'>
      <div className='card text-center py-5'>
        <div className='card-body disabled-component-warning w-75'>
          <Col md={12}>
            <ExclamationIcon width='64' fill='white' className='my-4' />
            <div>
              <FormattedMessage id='subscription-not-active' />
            </div>
            <div className='py-3'>
              <a href={`${process.env.REACT_APP_EXAMIST}subscriptions`}>
                <FormattedMessage id='pick-subscription' />
              </a>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Payment;

import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useLocation } from "react-router-dom"; // Import useLocation
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";
import Cookies from "js-cookie";

const Wrapper = (props) => {
  const [locale, setLocale] = useState({
    lang: "ar", // Keep the initial default
    messages: Arabic,
  });

  const location = useLocation(); // Get the current location

  // Detect language from the URL
  const detectLangFromURL = () => {
    const pathLang = location.pathname.split('/')[1]; // Get the first segment of the path
    return pathLang === 'en' ? 'en' : 'ar'; // Default to Arabic if it's not 'en'
  };

  const handleLang = () => {
    const langFromURL = detectLangFromURL(); // Detect language from URL

    setLocale({
      lang: langFromURL,
      messages: langFromURL === "ar" ? Arabic : English,
    });

    // Set direction and lang attributes in the document
    document.body.setAttribute("dir", langFromURL === "ar" ? "rtl" : "ltr");
    document.documentElement.lang = langFromURL;
    document.querySelector("title").textContent = langFromURL === "ar" ? "لوحة التحكم" : "Dashboard";

    // Optionally, store the language in cookies
    Cookies.set("site-lang", langFromURL);

    // Update the URL with the new language
    updateURLWithLang(langFromURL);
  };

  const updateURLWithLang = (lang) => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split('/')[1]; // Get the first segment of the path

    if (basePath !== lang) {
      const newPath = `/${lang}${currentPath.replace(/^\/(ar|en)/, '')}`; // Replace any existing lang prefix
      window.history.replaceState(null, '', newPath);
    }
  };

  useEffect(() => {
    handleLang(); // Set initial language when the component loads
  }, []);

  useEffect(() => {
    // Listen for route changes and update the URL with the language
    updateURLWithLang(locale.lang);
  }, [location, locale.lang]); // Update the URL when the route or language changes

  return (
    <Context.Provider value={{ locale, setLocale }}>
      <IntlProvider
        messages={locale.messages}
        locale={locale.lang}
        onError={() => {}}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;

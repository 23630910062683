import React from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
const TopBlockItem = ({ topcard }) => {
  const { svg, text, to, separatedHead } = topcard;

  if (separatedHead) return false;

  return (
    <>
      {text === "home" ? (
        ""
      ) : (
        <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
          <div className="card">
            <NavLink to={to}>
              <i>{svg}</i>
              <h4>
                <FormattedMessage id={text} />
              </h4>
            </NavLink>
          </div>
        </Col>
      )}
    </>
  );
};

export default TopBlockItem;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";
import QuestionComponent from "components/session/QuestionComponent";
import {
  getAllCategories,
  getCommentedOnQuestions,
  getNotifications,
  readNotification,
} from "store/actions";
import "styles/sessions.css";
import Select from "react-select";
import { getId, handleImage } from "helpers/functions";
import { IoClose, IoSearch } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { serarchInSaved } from "api/sessions";
import server from "api/server";
import { FaCircleQuestion } from "react-icons/fa6";
import { MdOutlineInsertComment } from "react-icons/md";
import ExamCommentInput from "components/session/ExamCommentInput";
import FsLightbox from "fslightbox-react";
import avatar from 'assets/images/avatar.png';
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const CommentedOn = () => {
  const getOptions = (arr) =>
    arr?.length > 0
      ? arr?.map((e) => ({
        label: e?.name?.[Cookies.get('site-lang') || 'ar'], // Changed to use Cookies
        value: e?.id || e?._id,
        subCategories: e?.subCategories || [],
        questionsCount: e?.questionsCount || "",
      }))
      : [{ label: formatMessage({ id: "no-options" }) }];
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState(' ');
  const [searchResults, setSearchResults] = useState([]);
  const handleSearch = async () => {
    try {
      const finalQuery = searchQuery.trim() === '' ? '' : searchQuery;

      const response = await server().get(`/auth/pinQuestions/search`, {
        params: {
          query: finalQuery,
          type: 'commented',
        },
      });

      if (response.status === 200) {
        setSearchResults(response.data);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };


  useEffect(() => {
    handleSearch();
  }, [searchQuery]);


  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    if (newQuery.trim() === '') {
      setSearchQuery('a');
    } else {
      setSearchQuery(newQuery);
    }

    setQuery(newQuery);
  };


  const { savedLoading, commentedOnLoading, error, commentedOnQuestions } =
    useSelector((state) => state.sessions);
  const { notifications } = useSelector((state) => state.user);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const [selectedSubCategoryChild, setSelectedSubCategoryChild] = useState();
  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);
  const location = useLocation();

  const { categories } = useSelector((state) => state.categories);
  const isSpecialUrl = location.pathname.includes('commented-on');

  useEffect(() => {
    dispatch(getCommentedOnQuestions());
    dispatch(getAllCategories());
    dispatch(getNotifications());
  }, [dispatch]);


  const thirdCatClass =
    subCategoriesChildren?.length > 0 ? "col-xl-3 col-md-4" : "";


  return (
    <div>
      <div className="session-add d-flex">
        {notifications
          ?.filter((n) => !n.read)
          ?.map((notif, index) => (
            <a
              className="saved-question-wrapper form-group card alert p-2 mx-2"
              href={`#${getId(notif)}`}
              key={index}
            >
              <h5 className="mb-0">
                <FormattedDate
                  value={notif?.createdAt}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              </h5>
            </a>
          ))}
      </div>

      <div className="welcome">
        <Row className="align-items-center">

          <Col md={6} >
            <div className="info">

              <h2>  <FormattedMessage id="commentedOn" /></h2>
            </div>

          </Col>
          <Col md={6} >
            <form>
              <div className="search position-relative">
                <IoSearch />
                <input
                  type="search"
                  placeholder="search"
                  value={query}
                  onChange={handleInputChange}
                />
              </div>
            </form>
          </Col>
        </Row>

      </div>



      <div className="card rounded-0">
        <div className="view-page session-add">
          <div className="session-exam">
            {searchResults?.data?.searchedQuestions?.length ? (
              <div className="details-wrapper row">
                {searchResults.data.searchedQuestions
                  .filter((ele) => !ele.isDeleted)
                  .map((data, index) => {
                    const explanation = data?.explanation || "";
                    const answers = data.answers || [];
                    const title = data?.title || "";
                    // const comments = data?.comments?.comment || "";
                    const idComments = data?.id;
                    // const _id = data?.comments.map((ele) => ele._id);
                    // const _id = data?.comments?.comment || "";
                    
                    return (
                      <CommentedOnQuestion
                        data={data}
                        index={index}
                        alert={notifications?.find(
                          (e) => getId(e?.question) === getId(data) && e?.read !== true
                        )}
                        key={index}
                        searchQuery={searchQuery}
                        idComments={idComments}
                        setSearchResults={setSearchResults}
                        // _id={_id}
                        title={title}
                        explanation={explanation}
                        // comments={comments}
                        handleSearch={handleSearch}
                        answers={answers.map((answer) => ({
                          text: answer.answer,
                          isCorrect: answer.isCorrect
                        }))}
                      />
                    );
                  })}

              </div>
            ) : (
              <span className="my-5 alert alert-warning text-center">
                <FormattedMessage id="notFound" />
              </span>
            )}
          </div>
        </div>
      </div>


    </div>
  );
};


const CommentedOnQuestion = ({
  data,
  index,
  searchQuery,
  setSearchResults,
  title,
  comments,
  answers,
  _id,
  idComments,
  handleSearch, 

  explanation,
  alert = false,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);

  const [discussQuestion, setDiscussion] = useState(false);
  const [show, setShow] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showExplain, setShowExplain] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (alert) {
      dispatch(readNotification(getId(alert)));
    }
  }, [alert, dispatch]);
  console.log(_id, "_idس")

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelector('.comments-wrapper')?.scrollTo(
        0,
        document.querySelector('.comments-wrapper')?.scrollHeight
      );
    }, 100);
    return () => clearTimeout(timeout);
  }, [data?.comments]);

  const showModalEX = () => {
    setShowExplain(true);
    setShow(false);
  };
  const deleteQwetion = async (_id) => {
    try {
      await server().delete(`auth/pinQuestions/commentedOn/${idComments}/${_id}`);
      toast.success(formatMessage({ id: 'question-removed' }));
      handleSearch();
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error(formatMessage({ id: 'delete-failed' }));
    }
  };


  
  const renderComments = Array.isArray(data?.comments)
  ? data.comments.map((c) => (
      <div className="comment_block" key={c?._id}>
        <div className="user_image_wrapper">
          <img
            className="user_image"
            src={
              c?.user?.image?.length > 0 && !c?.user?.image.includes('undefined')
                ? handleImage(c?.user?.image)
                : avatar
            }
            alt="avatar"
          />
        </div>
        <div className="comment_content">
          <span className="user_name">{c?.user?.fullName || ''}</span>
          <span className="comment_time">
            <FormattedTime value={c?.createdAt} />{' '}
            <FormattedDate
              value={c?.createdAt}
              day="numeric"
              month="long"
              year="numeric"
            />
          </span>
          <p className="comment_details">{c?.comment}</p>
        </div>
        <button className="close_btn" onClick={() => deleteQuestionComment(c?._id)}>
          <IoClose />
        </button>
      </div>
    ))
  : null;


 
  const deleteQuestionComment = async (commentId) => {
    try {
      await server().delete(`auth/pinQuestions/commentedOn/${idComments}/${commentId}`);
      toast.success(formatMessage({ id: 'question-removed' }));
  
      setSearchResults(prevResults => ({
        ...prevResults,
        data: {
          ...prevResults.data,
          searchedQuestions: prevResults.data.searchedQuestions.map(question => {
            if (question.id === data.id) {
              return {
                ...question,
                comments: question.comments.filter(comment => comment._id !== commentId)
              };
            }
            return question;
          }),
        },
      }));
    } catch (error) {
      console.error('Error deleting question comment:', error);
      toast.error(formatMessage({ id: 'delete-failed' }));
    }
  };

  if (!data) return <Loader />;

  return (
    <>
      {title && data?.comments?.length > 0 ? (
        <div className={`col-md-6 saved ${alert ? 'alert' : ''}`}>
          <div className="saved-question-wrapper saved">
            {window.location.href.includes('commented-on') && (
              <div className="icons">
                <button className="help" onClick={() => setShow(true)}>
                  <FaCircleQuestion />
                </button>
                <div className="d-flex align-items-center gap-3">
                  <button className="comments_btn" onClick={() => setShowComments(true)}>
                    <MdOutlineInsertComment />
                  </button>
                  <button className="close_btn" onClick={() => deleteQuestionComment(idComments)}>
                    <IoClose />
                  </button>
                </div>
              </div>
            )}

            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="div_modalSave">
                <button className="btn_transparent IoClose" onClick={() => setShow(false)}>
                  <IoClose />
                </button>
                <div className="explanation_modal title">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <Row className="answers_in_commented mt-4 text-start">
                  {answers.map((q, index) => (
                    <Col md={6} key={index}>
                      <div className={q.isCorrect ? 'sucess' : 'erorr'}>{q.text}</div>
                    </Col>
                  ))}
                </Row>
                <div className="d-flex align-items-center justify-content-around gap-3">
                  <button onClick={showModalEX} className="notes" type="button">
                    <FormattedMessage id="explain-question" />
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showExplain}
              onHide={() => setShowExplain(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="div_modalSave">
                <button className="btn_transparent IoClose" onClick={() => setShowExplain(false)}>
                  <IoClose />
                </button>
                <div
                  className="explanation_modal"
                  dangerouslySetInnerHTML={{ __html: explanation }}
                />
              </Modal.Body>
            </Modal>

            <Modal
              show={showComments}
              onHide={() => setShowComments(false)}
              dialogClassName="modal-90w"
              className="modal_comments"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="div_modalSave">
                <button className="btn_transparent IoClose" onClick={() => setShowComments(false)}>
                  <IoClose />
                </button>
                <div className="comments m-0">
                  <div className="title">
                    <FormattedMessage id="discussion" />
                  </div>

                  <div className="comments-wrapper">
                    {data?.comments?.length > 0 && renderComments}
                  </div>
                  {/* <div className="comments-wrapper">
                    <div
                      className="explanation_modal"
                      dangerouslySetInnerHTML={{ __html: comments }}
                    />
                  </div> */}
                  <ExamCommentInput question={data} />
                </div>
              </Modal.Body>
            </Modal>

            <QuestionComponent
              questionsState={[]}
              setQuestionsState={() => { }}
              questionsIndex={index}
              question={{ ...data, index }}
              discussQuestion={discussQuestion}
              setDiscussion={setDiscussion}
              viewOnly={true}
            />
          </div>
        </div>
      ) : null }
    </>
  );
};

export default CommentedOn; 